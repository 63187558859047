import React, { useEffect, useState } from 'react';
import SubTpRogerJr from './SubTemplate/SubTpRogerJr';
// import SubTpControl from './SubTemplate/SubTpControl';
import TpClSimulator from './../../Control/Application/CondensorLoop/Template/TpClSimulator';
import TpCondensorLoopMaster from './../../Control/Application/CondensorLoop/Template/TpCondensorLoopMaster';
import SubTpSectionLeader from './SubTemplate/SectionLeader/SubTpSectionLeader';
import SubTpMrRogerCH from './SubTemplate/MrRoger_CH/SubTpMrRoger_CH';
import SubTpMrRogerCHWP from './SubTemplate/MrRoger_CHWP/SubTpMrRoger_CHWP';
import SubTpMrRogerCWP from './SubTemplate/MrRoger_CWP/SubTpMrRoger_CWP';
import SubTpMrRoger_MAU from './SubTemplate/MrRoger_MAU/SubTpMrRoger_MAU';
import SubTpMrRogerCDWP from './SubTemplate/MrRoger_CDWP/SubTpMrRoger_CDWP';


const C_CtrlTp_List = [
    // {ht: 23, tp:(props)=><SubTpControl {...props}/>},
    {ht: 39, tp:(props)=><TpCondensorLoopMaster {...props}/>},
    {ht: 41, tp:(props)=><TpClSimulator {...props}/>},
    {ht: 47, tp:(props)=>(<SubTpRogerJr {...props}/>)},
    {ht: 57, tp:(props)=>(<SubTpSectionLeader {...props}/>)},
    {ht: 59, tp:(props)=>(<SubTpMrRogerCH {...props}/>)},
    {ht: 60, tp:(props)=>(<SubTpMrRogerCWP {...props}/>)},
    {ht: 61, tp:(props)=>(<SubTpMrRogerCHWP {...props}/>)},
    // {ht: 58, tp:(props)=>(<SubTpMrRoger_MAU {...props}/>)},
    {ht: 58, tp:(props)=>(<SubTpMrRogerCDWP {...props}/>)}
    
    
]

function TpList(props) {
    const [G_Ht, setG_Ht] = useState(0);    

    useEffect(()=>{
        async function startUp(){           

            if(props.ht) setG_Ht(props.ht);
        }
        startUp();

        return ()=>{
            // alert("AddDevicePg Page Close");
        }
        // eslint-disable-next-line
    }, []);

    // const tpList = [
    //     {ht: 23, tp:<SubTpControl {...props}/>},
    //     {ht: 39, tp:<TpCondensorLoopMaster {...props}/>},
    //     {ht: 41, tp:<TpClSimulator {...props}/>},
    //     {ht: 47, tp:<SubTpRogerJr {...props}/>},
        
    //     // 
    // ]

    const F_GetRespectiveTp=(nHt)=>{        
        let matchTp =  C_CtrlTp_List.find(c=>c.ht === nHt);
        if(matchTp) return matchTp.tp(props);
        return <div>No Match</div>
    }

    return (
        <div>
            {F_GetRespectiveTp(G_Ht)}
        </div>
    );
}

export { C_CtrlTp_List }; // Export tpList as a named export
export default TpList;
