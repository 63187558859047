import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { MdOutlineFileUpload } from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";
import { GrRevert } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";

const MRCdwpPgModbusDevice = (props) => {

  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  const [G_logicActive, setG_logicActive] = useState(false);
  const [G_HzHandling, setG_HzHandling] = useState(0);    /** 0=Ave, 1=Min, 2=Max */

  const [G_MemberList, setG_MemberList] = useState([
    { modbusId: 0 },
  ]);

  const ref_UploadDelay = useRef(null);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const F_AddModbus = () => {
    if (G_MemberList.length >= 10) return;
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.push({
      modbusId: 0
    });
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const Fin_SetInput=(i, sPara, _MemberList)=>{
    if(document.getElementById(`in_${props.pos}_${i}_${sPara}`)){
        document.getElementById(`in_${props.pos}_${i}_${sPara}`).value = _MemberList[i][sPara];
    }
  }

  const fDisplay_Info=(_MemberList)=>{
    for (let i = 0; i < 10; i++) {
        Fin_SetInput(i, "modbusId", _MemberList);
    }
    clearTimeout(ref_UploadDelay.current);
  }

  const F_DelayToShowPlaceData=(_MemberList)=>{
    // let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    ref_UploadDelay.current = setTimeout(()=>fDisplay_Info(_MemberList), 100);      
  }

  const F_UpdateInfo=()=>{
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    for (let i = 0; i < 10; i++) {
        if(document.getElementById(`in_${props.pos}_${i}_modbusId`)){
            _MemberList[i].modbusId = parseInt(document.getElementById(`in_${props.pos}_${i}_modbusId`).value);
        }
    }
    setG_MemberList(_MemberList);
  }

  const F_DeleteModbus = (ind) => {
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.splice(ind, 1);
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const Fin_invalidNo=(num)=>{
    if(isNaN(num) || num<= 0 ){
        return true;
    }
    return false;
  }

  const F_DownloadModbus = () => {
    /** get all inputs */
    if(props.handleDownloadModbus) {
      if(Fin_invalidNo(F_GetInputVal_Number(`cdwp_baurate_${props.pos}`))) return toast(`Invalid Baurate`);
      if(Fin_invalidNo(F_GetInputVal_Number(`cdwp_serial_${props.pos}`))) return toast(`Invalid Serial`);
      let cdwpSetting = { baurate: F_GetInputVal_Number(`cdwp_baurate_${props.pos}`),
                        serial: F_GetInputVal_Number(`cdwp_serial_${props.pos}`),
                        modBusDev: [] };
      for (let i = 0; i < G_MemberList.length; i++) {        
        if(Fin_invalidNo(G_MemberList[i].modbusId)) return toast(`Modbus[${i+1}], invalid ID`);
          cdwpSetting.modBusDev.push({
              modbusId: G_MemberList[i].modbusId
          })   
      }
      props.handleDownloadModbus(cdwpSetting);
    }
  }

  const handleCloseDia = () => {
    setG_statusState(0);
  };
  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };
  const setGw = () => {
    setG_statusState(1);
  };
  const F_ToggleActive = () => {
    if(G_logicActive) setG_logicActive(false);
    else setG_logicActive(true);
  };

  const F_GetHzHandlingDisplay=()=>{
    if(G_HzHandling===1) return "Min."
    if(G_HzHandling===2) return "Max."
    return "Ave."
  }
  const F_ToggerHzHandling=()=>{
    let _HzHandling = G_HzHandling+1;
    if(_HzHandling>2 || _HzHandling<0) _HzHandling=0;
    setG_HzHandling(_HzHandling);
  }

  const F_FillUpChwpSetting=(_pb, _pf, _pi)=>{

    setG_logicActive(_pb[0]);

    F_SetInputVal_Number(`chwp_QOT_${props.pos}`, _pi[0]/(1000));
    setG_HzHandling(_pi[1]);
    F_SetInputVal_Number(`chwp_Qty_${props.pos}`, _pi[2]);

    F_SetInputVal_Number(`chwp_dPTh_${props.pos}`, _pf[0]);
    F_SetInputVal_Number(`chwp_TargetDp_${props.pos}`, _pf[1]);
    F_SetInputVal_Number(`chwp_TopBuf_${props.pos}`, _pf[2]);
    F_SetInputVal_Number(`chwp_BtmBuf_${props.pos}`, _pf[3]);
    F_SetInputVal_Number(`chwp_MaxLimit_${props.pos}`, _pf[4]);
    F_SetInputVal_Number(`chwp_MinLimit_${props.pos}`, _pf[5]);
    F_SetInputVal_Number(`chwp_RampFast_${props.pos}`, _pf[6]);
    F_SetInputVal_Number(`chwp_RampSlow_${props.pos}`, _pf[7]);
    F_SetInputVal_Number(`chwp_SZR_Top_${props.pos}`, _pf[8]);
    F_SetInputVal_Number(`chwp_SZR_Btm_${props.pos}`, _pf[9]);

  }

  const F_DownladChwpSetting=()=>{
    let chwpSetting={
      logicActive:G_logicActive,

      Query_OT : F_GetInputVal_Number(`chwp_QOT_${props.pos}`),
      RdHzHandling:G_HzHandling,
      chwpQty : F_GetInputVal_Number(`chwp_Qty_${props.pos}`),

      dpThreshold : F_GetInputVal_Number(`chwp_dPTh_${props.pos}`),
      tagetDp : F_GetInputVal_Number(`chwp_TargetDp_${props.pos}`),
      buf_Top : F_GetInputVal_Number(`chwp_TopBuf_${props.pos}`),
      buf_Btm : F_GetInputVal_Number(`chwp_BtmBuf_${props.pos}`),
      hzMaxLimit : F_GetInputVal_Number(`chwp_MaxLimit_${props.pos}`),
      hzMinLimit : F_GetInputVal_Number(`chwp_MinLimit_${props.pos}`),
      RampRate_Fast : F_GetInputVal_Number(`chwp_RampFast_${props.pos}`),
      RampRate_Slow : F_GetInputVal_Number(`chwp_RampSlow_${props.pos}`),
      ZoneRatio_Top : F_GetInputVal_Number(`chwp_SZR_Top_${props.pos}`),
      ZoneRatio_Btm : F_GetInputVal_Number(`chwp_SZR_Btm_${props.pos}`),
    }

    let passCode = F_GetInputVal_Number(`chwp_pass_${props.pos}`);
    
    for (const key in chwpSetting) {
      if (Object.hasOwnProperty.call(chwpSetting, key)) {
        if(chwpSetting[key]===null) {
          toast(`Invalid Input (${key})`)
          return
        }        
      }
    }
    if (passCode === null) return toast(`Invalid input (Password)`);

    /** trigger parent function */
    if (props.handleDownloadSetting)
      props.handleDownloadSetting(chwpSetting, passCode);
    
  }
  const F_handleUplaod = () => {
    if (props.handleUploadModbus) props.handleUploadModbus();
  };

  const fBackToSetting=()=>{
    if(props.handleBackPg) props.handleBackPg(1);
}
  

  return (
    <div className="spring_SL_TabPg">
      <div className="sortHor">
        <div className="spring_leaderTitle">Modbus Device</div>
        <MdOutlineFileUpload
          className="reactCfgButtonOn stayRight hoverPointer"
          onClick={F_handleUplaod}
        />
        <div className='spring_ManuTitleBackIcon stayRight hoverPointer'
          onClick={fBackToSetting}>
          <GrRevert/>
        </div>
      </div>

      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Baurate</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`cdwp_baurate_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Serial</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`cdwp_serial_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

        {G_MemberList.map((c, ind) => (
            <div className="spring_tpContentGroup sortHor" key={`${props.pos}_${ind}_key`}>
                <div style={{marginRight:"10px"}}>{`${ind+1}.`}</div>
                <div className="sortHor">
                    <div>Modbus ID :</div>
                    <input style={{width:"60px"}}
                        className=" i_time_contentInput"
                        id={`in_${props.pos}_${ind}_modbusId`}
                        onChange={F_UpdateInfo}
                    ></input>
                </div>
                <div className="stayRight flexAndBothCenter hoverPointer" onClick={()=>F_DeleteModbus(ind)}><FiTrash2 className="redText"/></div>
            </div>
        ))}
        {G_MemberList.length < 10 ? 
            <div className="spring_tpContentGroup hoverPointer flexAndBothCenter boldText" onClick={F_AddModbus}>+ Add</div> :
            <div className="spring_tpContentGroup"> Reach Max Member</div>}
        {/* {G_MemberList.length < 8 && <div className="spring_Sl_Slot hoverPointer" onClick={F_AddPara}>+ Add</div>} */}

        <div className="spring_tpContentSendButton hoverPointer" onClick={F_DownloadModbus}>Send</div>
     

      {/* <div className="spring_SL_DotsList"> */}
        {/* {G_MemberList.map((c, ind) => (
          <div className="sortHor" key={`DotList_${props.pos}_${ind}`}>
            <div className="spring_marginRight5">{`${ind + 1}.`}</div>
            <div className="spring_SL_CfgDotsFrame">
                <div className="sortHor">
                  <div>Modbus ID:</div>
                  <input
                    style={{ width: "60%" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_modbusId`}
                    onChange={F_UpdateInfo}
                  ></input>
                </div>
              </div>
            <div
              className="hoverPointer redText"
              style={{ marginLeft: "5px" }}
              onClick={() => F_DeleteModbus(ind)}
            >
              <FiTrash2 />
            </div>
          </div>
        ))}
        {G_MemberList.length < 10 && <div className="spring_SL_CfgDotsFrame flexAndBothCenter hoverPointer boldText" onClick={F_AddModbus} style={{ margin: "0 20px 0 17px" }}> 
          + Add
        </div>}
        {G_MemberList.length >= 10 && <div className="spring_SL_CfgDotsFrame">
          Max
        </div>}
        <div className="spring_tpContentSendButton hoverPointer" 
        onClick={F_DownloadModbus}>
          Send
        </div> */}

      {/* </div> */}

      <div style={{ marginBottom: "25px" }}></div>
    </div>
  );
};

export default MRCdwpPgModbusDevice;
