import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
// import { MdOutlineFileUpload } from "react-icons/md";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { BsFillTriangleFill } from "react-icons/bs";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";
import MRCdwpPgModbusDevice from "./MRCdwpPg_ModbusDevice";
import MRCdwpPgVfdFreq from "./MRCdwpPg_VfdFreq";

const MRCdwpTabSetting = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadChwpSetting(_pb, _pf, _pi) {
      F_FillUpChwpSetting(_pb, _pf, _pi);
    },
  }));

  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  const [G_logicActive, setG_logicActive] = useState(false);
  const [G_HzHandling, setG_HzHandling] = useState(0);    /** 0=Ave, 1=Min, 2=Max */

  const [G_ShowingTab, setG_ShowingTab] = useState(0);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const handleCloseDia = () => {
    setG_statusState(0);
  };
  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };
  const setGw = () => {
    setG_statusState(1);
  };

  const F_ToggleActive = () => {
    if(G_logicActive) setG_logicActive(false);
    else setG_logicActive(true);
  };

  const F_GetHzHandlingDisplay=()=>{
    if(G_HzHandling===1) return "Min."
    if(G_HzHandling===2) return "Max."
    return "Ave."
  }
  const F_ToggerHzHandling=()=>{
    let _HzHandling = G_HzHandling+1;
    if(_HzHandling>2 || _HzHandling<0) _HzHandling=0;
    setG_HzHandling(_HzHandling);
  }

  const F_FillUpChwpSetting=(_pb, _pf, _pi)=>{

    setG_logicActive(_pb[0]);

    F_SetInputVal_Number(`chwp_QOT_${props.pos}`, _pi[0]/(1000));
    setG_HzHandling(_pi[1]);
    F_SetInputVal_Number(`chwp_Qty_${props.pos}`, _pi[2]);

    F_SetInputVal_Number(`chwp_dPTh_${props.pos}`, _pf[0]);
    F_SetInputVal_Number(`chwp_TargetDp_${props.pos}`, _pf[1]);
    F_SetInputVal_Number(`chwp_TopBuf_${props.pos}`, _pf[2]);
    F_SetInputVal_Number(`chwp_BtmBuf_${props.pos}`, _pf[3]);
    F_SetInputVal_Number(`chwp_MaxLimit_${props.pos}`, _pf[4]);
    F_SetInputVal_Number(`chwp_MinLimit_${props.pos}`, _pf[5]);
    F_SetInputVal_Number(`chwp_RampFast_${props.pos}`, _pf[6]);
    F_SetInputVal_Number(`chwp_RampSlow_${props.pos}`, _pf[7]);
    F_SetInputVal_Number(`chwp_SZR_Top_${props.pos}`, _pf[8]);
    F_SetInputVal_Number(`chwp_SZR_Btm_${props.pos}`, _pf[9]);

  }

  const F_DownladChwpSetting=()=>{
    let chwpSetting={
      logicActive:G_logicActive,

      Query_OT : F_GetInputVal_Number(`chwp_QOT_${props.pos}`),
      RdHzHandling:G_HzHandling,
      chwpQty : F_GetInputVal_Number(`chwp_Qty_${props.pos}`),

      dpThreshold : F_GetInputVal_Number(`chwp_dPTh_${props.pos}`),
      tagetDp : F_GetInputVal_Number(`chwp_TargetDp_${props.pos}`),
      buf_Top : F_GetInputVal_Number(`chwp_TopBuf_${props.pos}`),
      buf_Btm : F_GetInputVal_Number(`chwp_BtmBuf_${props.pos}`),
      hzMaxLimit : F_GetInputVal_Number(`chwp_MaxLimit_${props.pos}`),
      hzMinLimit : F_GetInputVal_Number(`chwp_MinLimit_${props.pos}`),
      RampRate_Fast : F_GetInputVal_Number(`chwp_RampFast_${props.pos}`),
      RampRate_Slow : F_GetInputVal_Number(`chwp_RampSlow_${props.pos}`),
      ZoneRatio_Top : F_GetInputVal_Number(`chwp_SZR_Top_${props.pos}`),
      ZoneRatio_Btm : F_GetInputVal_Number(`chwp_SZR_Btm_${props.pos}`),
    }

    let passCode = F_GetInputVal_Number(`chwp_pass_${props.pos}`);
    
    for (const key in chwpSetting) {
      if (Object.hasOwnProperty.call(chwpSetting, key)) {
        if(chwpSetting[key]===null) {
          toast(`Invalid Input (${key})`)
          return
        }        
      }
    }
    if (passCode === null) return toast(`Invalid input (Password)`);

    /** trigger parent function */
    if (props.handleDownloadSetting)
      props.handleDownloadSetting(chwpSetting, passCode);
    
  }
  const F_handleUplaod = () => {
    if (props.handleUploadModbus) props.handleUploadModbus();
  };
  
  const F_ToPgNo = (nPgNo) => {
    if(props.handleChangePg) props.handleChangePg(nPgNo)
  };

  return (
    <div className="spring_SL_TabPg">
        {G_statusState === 1 && (
            <TpDiaGatewayID
                divInfo={props.dev}
                gwPair={G_GwID}
                onClickDiaClose={handleCloseDia}
                setGwId={handleSetGwDisplay}
            />
        )}


            {/* <div className="sortHor">
                <div className="spring_tpContentTitle">Gateway</div>
            </div>

            <div className="spring_tpContentGroup">
                <div className="sortHor spring_tpContent">
                <div>Pair Gateway</div>
                <div
                    className="stayRight blueText spring_tpContentValue hoverPointer"
                    onClick={setGw}
                >
                    {G_GwID}
                </div>
                </div>
            </div> */}

        <div className="spring_RjSettingGroupTitle">Gateway</div>
        <div className="spring_editScheGroup">
            <div className="spring_editItemBar sortHor">
            <div className="spring_editLeftTitle">Pair Gateway</div>
            <div
                className="spring_editRightItem  blueText hoverPointer"
                onClick={setGw}
            >
                {G_GwID > 0 ? (
                <div>{G_GwID}</div>
                ) : (
                <div>{"< Insert Gateway ID >"}</div>
                )}
            </div>
            </div>
        </div>

        <div className="spring_RjSettingGroupTitle">Settings</div>
            
        <div className="spring_editScheGroup_SmallSpaceContainer">
            <div className="spring_editScheGroup_SmallSpace">
                <div
                    className="spring_editItemBar sortHor hoverPointer"
                    onClick={() => F_ToPgNo(2)}
                >
                    <div className="spring_editLeftTitle">Modbus Device</div>            
                    <div className="spring_editRightItem  flexAndBothCenter">
                        <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
                    </div>
                </div>
            </div>

            <div className="spring_editScheGroup_SmallSpace">
                <div
                    className="spring_editItemBar sortHor hoverPointer"
                    onClick={() => F_ToPgNo(3)}
                >
                    <div className="spring_editLeftTitle">VFD Frequency</div>            
                    <div className="spring_editRightItem  flexAndBothCenter">
                        <BsFillTriangleFill className="rotate90deg text_0_7 marginR_5px blueText"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
});

export default MRCdwpTabSetting;
