import React, { useEffect, useRef, useState } from "react";
import ctrlTp from "../../CtrlCmdTemplate/CtrlTemplate";
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";
import ctrlCmdLib from "../../../../../../function/V2_DeviceControlLib/V2_DeviceControlLib";
import v2ctrlFn from "../../../../../../function/V2_Control/V2_ControlFn";
import { notEmptyArr } from "../../../../../../function/ArrayFn/arrayFn";
import TpDiaSending from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSending";
import TpDiaSuccess from "../../../../Control/Common/Dialog/StatusDialog/TpDiaSuccess";
import TpDiaFailed from "../../../../Control/Common/Dialog/StatusDialog/TpDiaFailed";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineAdjustments } from "react-icons/hi";
import MRCdwpTabSetting from "./MRCdwpTab_Setting";
import MRCdwpTabControl from "./MRCdwpTab_Control";
import MRCdwpPgModbusDevice from "./MRCdwpPg_ModbusDevice";
import MRCdwpPgVfdFreq from "./MRCdwpPg_VfdFreq";

const SubTpMrRogerCDWP = (props) => {
  /**------Ctrl Standard 1/2 Start------ */
  const cmdTimeout = 10000; // 10 sec
  const msgTimeout = 3000; // 3 sec

  const [G_statusState, setG_statusState] = useState(0);
  const [G_diaErrMsg, setG_diaErrMsg] = useState("");
  const [G_GwID, setG_GwID] = useState();

  const cmdTimeoutRef = useRef(null);
  const statusTimeoutRef = useRef(null);
  const refCmdLog = useRef({});
  /**======Ctrl Standard 1/2 End====== */

  const [G_ShowingTab, setG_ShowingTab] = useState(1);

  const ref_Setting = useRef(); // parent trig child
  const ref_LNode = useRef();   // parent trig child


  useEffect(() => {
    let socket;
    async function startUp() {
      socket = socketIOClient(process.env.REACT_APP_PUBLIC_URL);

      await F_LoadGateway();

      let topic = ctrlTp.F_GetIoTopic(props.dev);
      socket.on(topic, async (data) => {
        if (
          ctrlTp.F_HandleNodeReply(
            data,
            refCmdLog.current,
            cmdTimeoutRef.current,
            setG_statusState
          )
        ) {
          statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
          await handleServerReq(data);
        }
      });
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(cmdTimeoutRef.current);
      clearTimeout(statusTimeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);
  /**-----------Ctrl Standard 2/2 start------------- */
  const F_LoadGateway = async () => {
    let pairedGw = await v2ctrlFn.getV2_GwPair(props.dev._id);
    if (pairedGw.errMsg) return toast(`Cmd Err: ${pairedGw.errMsg}`);
    let { gwPairList } = pairedGw;
    if (!notEmptyArr(gwPairList))
      return toast("No gateway pair to this device");
    setG_GwID(gwPairList[0].gwid);
  };

  const fCmdTimeout = async () => {
    await ctrlTp.F_CmdTimeout(
      refCmdLog.current,
      setG_diaErrMsg,
      setG_statusState,
      cmdTimeoutRef.current
    );
    /** trigger failed countdown */
    statusTimeoutRef.current = setTimeout(statusDiaAutoClose, msgTimeout);
  };
  const statusDiaAutoClose = () => {
    handleCloseDia();
  };

  const handleCloseDia = () => {
    // clearTimeout(cmdTimeoutRef.current);
    // clearTimeout(statusTimeoutRef.current);
    setG_statusState(0);
  };

  const F_SendLoraTp = async (fn, payload) => {
    console.log("gateway" ,G_GwID);
    let devInfo = { type: props.ht, devID: props.dev.devID };
    let cmdRel = await ctrlCmdLib.v2_CtrlCmd(fn, G_GwID, devInfo, payload, 1); // lora fn, gwid, {type, devID}, paylaod, broker(1=aws, 2 koala)
    setG_statusState(1); // go to sending
    /** start send command time out */
    cmdTimeoutRef.current = setTimeout(fCmdTimeout, cmdTimeout);
    refCmdLog.current = cmdRel.loraPackage;
  };

  const handleServerReq = async (mqttData) => {
    try {
      let { hf, pf, pb, pi } = mqttData;
      if (hf === 12) {
        ref_Setting.current.uploadSetting(pb, pi);
      }else if(hf===11){
        // F_FillUpUploadedLNode(pf, pi);
        ref_LNode.current.uploadLNode(pf, pi);
      }
    } catch (error) {
      toast(`Server Req Err: ${error.message}`);
    }
  };
  /** ===============Ctrl Standard 2/2 end=============== */

  const F_ToPgNo = (nPgNo) => {
    setG_ShowingTab(nPgNo);
  };

  const F_downloadModbus = async (cdwpSetting) => {
    console.log("cdwpSetting", cdwpSetting);
    // console.log("passCode", passCode);
    let pi=[
      parseInt(cdwpSetting.baurate),
      parseInt(cdwpSetting.serial)
    ]

    for (const eachMb of cdwpSetting.modBusDev) {
        pi.push(parseInt(eachMb.modbusId));
        // pi.push(parseInt(eachMb.bdDev_id));
    }
  
      for(let i = cdwpSetting.modBusDev.length; i < 10; i++) {
        pi.push(0);
        // pi.push(0);
      }
    // let pn=[passCode];
    let payload = {pi};
    console.log("payload", payload);
    
    await F_SendLoraTp(2, payload);
  };

  const F_uploadModBus = async () => {
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(12, payload);
  }
  
  const F_downloadVfdFreq = async (freqInfo)=>{
    console.log("freqInfo", freqInfo);
    
    let pi = [];

    for(let i = 0; i < 10; i++) {
        if(freqInfo.mbAddr.length > i) {
            pi.push(parseInt(freqInfo.mbAddr[i]))
        } else {
            pi.push(0);
        }
    }

    for(let i = 0; i < 10; i++) {
        if(freqInfo.writeMethod.length > i) {
            pi.push(parseInt(freqInfo.writeMethod[i]))
        } else {
            pi.push(0);
        }
    }

    let payload = {pi};

    // console.log("LNodeInfo", LNodeInfo);
    console.log("payload", payload);
    
    // return toast("Testing Break")
    await F_SendLoraTp(3, payload);
  }
  const F_uploadVfdFreq =async ()=>{
    let pi=[1];
    let payload = {pi};
    /** get device {type, devID} info */
    await F_SendLoraTp(11, payload);
  }

  const F_downloadControl = async(ctrlInfo) =>{
    let pf = [ctrlInfo.freq];

    let payload = {pf};

    console.log("payload", payload);
    
    await F_SendLoraTp(4, payload);
  }
  
  const F_uploadControl = async() => {

  }

  return (
    <div>
      {G_statusState !== 0 && <div className="spring_dialogBlurBg"></div>}
      {G_statusState === 1 && <TpDiaSending />}
      {G_statusState === 2 && <TpDiaSuccess onClickDiaClose={handleCloseDia} />}
      {G_statusState === 3 && (
        <TpDiaFailed onClickDiaClose={handleCloseDia} diaErrMsg={G_diaErrMsg} />
      )}

      {G_ShowingTab === 1 && (
        <MRCdwpTabSetting
          dev={props.dev}
          pos={props.pos}
          gwId={G_GwID}
          handleChangePg={F_ToPgNo}
        //   handleDownloadModbus={F_downloadModbus}
        //   handleUploadModbus={F_uploadModBus}
        //   handleDownloadVfdFreq={F_downloadVfdFreq}
        //   handleUploadVfdFreq={F_uploadVfdFreq}
          ref = {ref_Setting}
        />
      )}
      
      {G_ShowingTab === 0 && <MRCdwpTabControl 
        dev={props.dev}
        pos={props.pos}
        handleDownloadControl={F_downloadControl}
        handleUploadControl={F_uploadControl}    
        ref = {ref_LNode}
      />}

        {G_ShowingTab === 2 && <MRCdwpPgModbusDevice 
            dev={props.dev}
            pos={props.pos}
            handleBackPg={F_ToPgNo}
            handleDownloadModbus={F_downloadModbus}
            handleUploadModbus={F_uploadModBus}    
                // ref = {ref_LNode}
        />}

        {G_ShowingTab === 3 && <MRCdwpPgVfdFreq 
            dev={props.dev}
            pos={props.pos}
            handleBackPg={F_ToPgNo}
            handleDownloadVfdFreq={F_downloadVfdFreq}
            handleUploadVfdFreq={F_uploadVfdFreq}
                // ref = {ref_LNode}
        />}

      <div className="spring_TpBottomBar sortHor">
        <div className={`spring_TpBottomBar_ItemFrame ${G_ShowingTab === 0?"spring_shadowBox_Small":""} hoverPointer`} onClick={()=>F_ToPgNo(0)}>
          <HiOutlineAdjustments />
          <div className="spring_TpBtmButtonTitle">Control</div>
        </div>
        <div
          className={`spring_TpBottomBar_ItemFrame ${
            G_ShowingTab > 0  ? "spring_shadowBox_Small" : ""
          } hoverPointer`}
          onClick={() => F_ToPgNo(1)}
        >
          <AiOutlineSetting />
          <div className="spring_TpBtmButtonTitle">Settings</div>
        </div>
        
      </div>

    </div>
  );
};

export default SubTpMrRogerCDWP;
