import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { MdOutlineFileUpload } from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { toast } from "react-toastify";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";


const MRCwpTabSetting = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadSetting(_pb, _pi) {
      F_FillUpCwpSetting(_pb, _pi);
    },
  }));

  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  const [G_logicActive, setG_logicActive] = useState(false);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  
  const handleCloseDia = () => {
    setG_statusState(0);
  };
  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };
  const setGw = () => {
    setG_statusState(1);
  };
  const F_ToggleActive = () => {
    if(G_logicActive) setG_logicActive(false);
    else setG_logicActive(true);
  };
  
  const F_GetControlMethodDisplay = () => {
    return 'Fixed'
  }

  const F_FillUpCwpSetting = (_pb, _pi) => {
    setG_logicActive(_pb[0]);
    // _pi[0] ==> control method, fixed to 0
    F_SetInputVal_Number(`cwp_Qty_${props.pos}`, _pi[1]);
    F_SetInputVal_Number(`cwp_maxQty_${props.pos}`, _pi[2]);
    F_SetInputVal_Number(`cwp_minQty_${props.pos}`, _pi[3]);
  };


  const F_DownladCwpSetting = () => {
    let cwpSetting={
      logicActive:G_logicActive,
      ctrlMethod : 0,
      cwpQty : F_GetInputVal_Number(`cwp_Qty_${props.pos}`),
      MaxQty : F_GetInputVal_Number(`cwp_maxQty_${props.pos}`),
      MinQty : F_GetInputVal_Number(`cwp_minQty_${props.pos}`),
    }
    let passCode = F_GetInputVal_Number(`cwp_pass_${props.pos}`);

    for (const key in cwpSetting) {
      if (Object.hasOwnProperty.call(cwpSetting, key)) {
        if(cwpSetting[key]===null){
          toast(`Invalid input (${key})`)
          return
        }        
      }
    }
    if (passCode === null) return toast(`Invalid input (Password)`);

    /** input validation done. */
    if (props.handleDownloadSetting)
      props.handleDownloadSetting(cwpSetting, passCode);
  }
  
  const F_handleUplaod = () => {
    if (props.handleUploadSetting) props.handleUploadSetting();
  };
  
  

  return (
    <div className="spring_SL_TabPg">
      {G_statusState === 1 && (
        <TpDiaGatewayID
          divInfo={props.dev}
          gwPair={G_GwID}
          onClickDiaClose={handleCloseDia}
          setGwId={handleSetGwDisplay}
        />
      )}

      <div className="sortHor">
        <div className="spring_tpContentTitle">General</div>
        <MdOutlineFileUpload
          className="reactCfgButtonOn stayRight hoverPointer"
          style={{ marginRight: "20px" }}
            onClick={F_handleUplaod}
        />
      </div>

      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Logic Active</div>
          <div
            className="stayRight spring_tpContentValue hoverPointer"
            onClick={F_ToggleActive}
          >
            {G_logicActive ? <BsToggleOn className="reactCfgButtonOn" />: <BsToggleOff />}
          </div>
          {/* <div className="stayRight blueText spring_tpContentValue">
            1
          </div> */}
        </div>
        <div className="sortHor spring_tpContent">
          <div>Pair Gateway</div>
          <div
            className="stayRight blueText spring_tpContentValue hoverPointer"
            onClick={setGw}
          >
            {G_GwID}
          </div>
        </div>
      </div>
      
      <div className="spring_tpContentTitle">CWP</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Control Method</div>
          <div className="stayRight">
            <div className="spring_SL_SelButton hoverPointer"
            // onClick={F_ToggerHzHandling}
            >
              {F_GetControlMethodDisplay()}
              </div>
          </div>
        </div>
        <div className="sortHor spring_tpContent">
          <div>CWP Qty.</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`cwp_Qty_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Max CWP Qty.</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`cwp_maxQty_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
        <div className="sortHor spring_tpContent">
          <div>Min CWP Qty.</div>
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`cwp_minQty_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div className="spring_tpContentTitle">Security</div>
      <div className="spring_tpContentGroup">
        <div className="sortHor spring_tpContent">
          <div>Password</div>
          {/* <div className="stayRight blueText spring_tpContentValue">1</div> */}
          <input
            style={{ width: "100px", textAlign: "end" }}
            type={"number"}
            className=" i_time_contentInput hideNumberUpDown stayRight spring_tpContentValue"
            id={`cwp_pass_${props.pos}`}
            placeholder="--"
            // onChange={F_UpdateInfo}
          ></input>
        </div>
      </div>

      <div
        className="spring_tpContentSendButton hoverPointer"
        onClick={F_DownladCwpSetting}
      >
        Update
      </div>

      <div style={{ marginBottom: "25px" }}></div>
    </div>
  );
});

export default MRCwpTabSetting;
