import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { MdOutlineFileUpload } from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";
import { GrRevert } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";

const MRCdwpPgVfdFreq = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async uploadChwpSetting(_pb, _pf, _pi) {
      F_FillUpChwpSetting(_pb, _pf, _pi);
    },
  }));

  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  const [G_logicActive, setG_logicActive] = useState(false);
  const [G_HzHandling, setG_HzHandling] = useState(0);    /** 0=Ave, 1=Min, 2=Max */

  const [G_MemberList, setG_MemberList] = useState([
    { mbAddr: 0, writeMethod: 0 },
  ]);

  const ref_UploadDelay = useRef(null);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const F_AddFreq = () => {
    if (G_MemberList.length >= 10) return;
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.push({
      mbAddr: 0,
      writeMethod: 0,
    });
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const Fin_SetInput=(i, sPara, _MemberList)=>{
    if(document.getElementById(`in_${props.pos}_${i}_${sPara}`)){
        document.getElementById(`in_${props.pos}_${i}_${sPara}`).value = _MemberList[i][sPara];
    }
  }

  const fDisplay_Info=(_MemberList)=>{
    for (let i = 0; i < 8; i++) {
        Fin_SetInput(i, "mbAddr", _MemberList);
        Fin_SetInput(i, "writeMethod", _MemberList);
    }
    clearTimeout(ref_UploadDelay.current);
  }

  const F_DelayToShowPlaceData=(_MemberList)=>{
    // let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    ref_UploadDelay.current = setTimeout(()=>fDisplay_Info(_MemberList), 100);      
  }

  const F_UpdateInfo=()=>{
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    for (let i = 0; i < 10; i++) {
        if(document.getElementById(`in_${props.pos}_${i}_mbAddr`)){
            _MemberList[i].mbAddr = parseInt(document.getElementById(`in_${props.pos}_${i}_mbAddr`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_writeMethod`)){
            _MemberList[i].writeMethod = parseInt(document.getElementById(`in_${props.pos}_${i}_writeMethod`).value);
        }
    }
    setG_MemberList(_MemberList);
  }

  const F_DeleteFreq = (ind) => {
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.splice(ind, 1);
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const Fin_invalidNo=(num)=>{
    if(isNaN(num) || num<= 0 ){
        return true;
    }
    return false;
  }

  const F_DownloadFreq = () => {
    if(props.handleDownloadVfdFreq) {
        let freqInfo = { mbAddr: [], writeMethod: [] };
        for (let i = 0; i < G_MemberList.length; i++) {        
            if(Fin_invalidNo(G_MemberList[i].mbAddr)) return toast(`Freq[${i+1}], invalid Address`);
            if(Fin_invalidNo(G_MemberList[i].writeMethod)) return toast(`Freq[${i+1}], invalid Write Method`);
            freqInfo.mbAddr.push(G_MemberList[i].mbAddr);
            freqInfo.writeMethod.push(G_MemberList[i].writeMethod);
        }
        props.handleDownloadVfdFreq(freqInfo);
    }
  }

  const F_FillUpChwpSetting=(_pb, _pf, _pi)=>{

    setG_logicActive(_pb[0]);

    F_SetInputVal_Number(`chwp_QOT_${props.pos}`, _pi[0]/(1000));
    setG_HzHandling(_pi[1]);
    F_SetInputVal_Number(`chwp_Qty_${props.pos}`, _pi[2]);

    F_SetInputVal_Number(`chwp_dPTh_${props.pos}`, _pf[0]);
    F_SetInputVal_Number(`chwp_TargetDp_${props.pos}`, _pf[1]);
    F_SetInputVal_Number(`chwp_TopBuf_${props.pos}`, _pf[2]);
    F_SetInputVal_Number(`chwp_BtmBuf_${props.pos}`, _pf[3]);
    F_SetInputVal_Number(`chwp_MaxLimit_${props.pos}`, _pf[4]);
    F_SetInputVal_Number(`chwp_MinLimit_${props.pos}`, _pf[5]);
    F_SetInputVal_Number(`chwp_RampFast_${props.pos}`, _pf[6]);
    F_SetInputVal_Number(`chwp_RampSlow_${props.pos}`, _pf[7]);
    F_SetInputVal_Number(`chwp_SZR_Top_${props.pos}`, _pf[8]);
    F_SetInputVal_Number(`chwp_SZR_Btm_${props.pos}`, _pf[9]);

  }

  const F_handleUplaod = () => {
    if (props.handleUploadVfdFreq) props.handleUploadVfdFreq();
  };

  const fBackToSetting=()=>{
    if(props.handleBackPg) props.handleBackPg(1);
  }
  

  return (
    <div className="spring_SL_TabPg">
      <div className="sortHor">
        <div className="spring_leaderTitle">VFD Frequency</div>
        <MdOutlineFileUpload
          className="reactCfgButtonOn stayRight hoverPointer"
          onClick={F_handleUplaod}
        />
        <div className='spring_ManuTitleBackIcon stayRight hoverPointer'
          onClick={fBackToSetting}>
          <GrRevert/>
        </div>
      </div>
      
      {G_MemberList.map((c, ind) => (
          <div className="sortHor" key={`DotList_${props.pos}_${ind}`}>
            <div className="spring_marginRight5">{`${ind + 1}.`}</div>
            <div className="spring_SL_CfgDotsFrame">
                <div className="sortHor">
                  <div>Modbus Address:</div>
                  <input
                    style={{ width: "40%" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_mbAddr`}
                    onChange={F_UpdateInfo}
                  ></input>
                </div>
                <div className="sortHor">
                  <div>Write Method:</div>
                  <input
                    style={{ width: "40%" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_writeMethod`}
                    onChange={F_UpdateInfo}
                  ></input>
                </div>
              </div>
            <div
              className="hoverPointer redText"
              style={{ marginLeft: "5px" }}
              onClick={() => F_DeleteFreq(ind)}
            >
              <FiTrash2 />
            </div>
          </div>
        ))}
        {G_MemberList.length < 10 && <div className="spring_SL_CfgDotsFrame flexAndBothCenter hoverPointer boldText" onClick={F_AddFreq} style={{ margin: "0 20px 0 17px" }}> 
          + Add
        </div>}
        {G_MemberList.length >= 10 && <div className="spring_SL_CfgDotsFrame">
          Max
        </div>}
        <div className="spring_tpContentSendButton hoverPointer" 
        onClick={F_DownloadFreq}>
          Send
        </div>
    </div>
  );
});

export default MRCdwpPgVfdFreq;
