import http from "../httpService";


async function getV2_GwPair(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getgwpair";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_GwPair(bdDev_id, gwid) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setgwpair";
    let body = {bdDev_id, gwid};
    return await http.post(url, body);  
}

async function getV2_LNodeDots(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getlnodedots";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_LNodeDots(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setlnodedots";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

async function getV2_LNodeParaPg(bdDev_id) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/getlnodeparapg";
    let body = {bdDev_id};
    return await http.post(url, body);  
}

async function setV2_LNodeParaPg(bdDev_id, payload) {  
    let url = process.env.REACT_APP_PUBLIC_URL+"/v2ctrl/setlnodeparapg";
    let body = {bdDev_id, payload};
    return await http.post(url, body);  
}

const expModule = {  
    getV2_GwPair,
    setV2_GwPair,
    getV2_LNodeDots,
    setV2_LNodeDots,
    getV2_LNodeParaPg,
    setV2_LNodeParaPg
};
export default expModule;