import React, { useEffect, useState } from "react";
import TpDiaGatewayID from "../../../../Control/Common/Dialog/Input/TpDiaGatewayID";
import { F_GetInputVal_Number, F_SetInputVal_Number } from "../CommonFn/SubTpCommonFn";
import { toast } from "react-toastify";

const MRCdwpTabControl = (props) => {
  const [G_statusState, setG_statusState] = useState(0);
  const [G_GwID, setG_GwID] = useState(0);

  const [G_freq, setG_freq] = useState(0);

  useEffect(() => {
    async function startUp() {
      if (!props.gwId) setG_GwID(0);
      else setG_GwID(props.gwId);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      //   clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.gwId]);

  const F_UpdateInfo=()=>{
    let _freq;
    if(document.getElementById(`in_${props.pos}_freq`)){
        // _MemberList[i].ht = document.getElementById(`in_${props.pos}_${i}_devTy`).value;
        _freq = document.getElementById(`in_${props.pos}_freq`).value;
    }
    setG_freq(_freq);
  }

  const handleCloseDia = () => {
    setG_statusState(0);
  };
  const handleSetGwDisplay = (gwid) => {
    setG_GwID(gwid);
    if (props.setGwId) props.setGwId(gwid);
  };
  const setGw = () => {
    setG_statusState(1);
  };

  const Fin_invalidNo=(num)=>{
    if(isNaN(num) || num<= 0 ){
        return true;
    }
    return false;
  }

  const F_downloadControl = () => {
    if(props.handleDownloadControl) {
        if(Fin_invalidNo(F_GetInputVal_Number(`in_${props.pos}_ctrl_freq`))) return toast(`Invalid Frequency`);
        let ctrlInfo = { freq: F_GetInputVal_Number(`in_${props.pos}_ctrl_freq`) };
        props.handleDownloadControl(ctrlInfo);
    }
  }

  return (
    <div className="spring_SL_TabPg">
        <div>Manual</div>
        <div>Frequency:</div>
        <div className="spring_Sl_Slot">
            <input style={{width:"60px"}}
                className=" i_time_contentInput"
                id={`in_${props.pos}_ctrl_freq`}
                onChange={F_UpdateInfo}
            ></input>
        </div>

        <div
            className="spring_tpContentSendButton hoverPointer"
            onClick={F_downloadControl}
        >
            Update
        </div>
    </div>
  );
};

export default MRCdwpTabControl;
