import React, { useEffect, useRef, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import { MdOutlineFileUpload } from "react-icons/md";

const SLTabCfgDots = (props) => {
  const [G_MemberList, setG_MemberList] = useState([
    { ht: 1, hi: 2, bdDev_id: 3, comType: 1, dotGwID: 4, loraFreq: 433.0 },
  ]);
  const [G_UploadedGwID, setG_UploadedGwID] = useState(0);

  const ref_UploadDelay = useRef(null);

  useEffect(() => {
    // console.log("props", );
    
    async function startUp() {
        let {memlist, gwId} = F_GetDotsData();
        F_DelayToShowPlaceData(memlist);      
        setG_UploadedGwID(gwId)
        setG_MemberList(memlist);
    }
    startUp();

    return () => {
      // alert("AddDevicePg Page Close");
      clearTimeout(ref_UploadDelay.current);
    };
    // eslint-disable-next-line
  }, [props.uploadedDotsInfo]);

  const F_GetDotsData = () => {
    let memlist = [];
    let gwId = 0;
    if(props.uploadedDotsInfo && props.uploadedDotsInfo.dotsInfo){
        for (const eachDots of props.uploadedDotsInfo.dotsInfo) {
            if(!Fin_invalidNo(eachDots.ht) && !Fin_invalidNo(eachDots.hi)){
                memlist.push(eachDots);
            }
        }
    }
    if(props.uploadedDotsInfo && props.uploadedDotsInfo.gwID){
        gwId = props.uploadedDotsInfo.gwID;
    }
    return {memlist, gwId}
  }

  const F_ToggleComType = (ind) => {
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    if (_MemberList[ind].comType === 1) _MemberList[ind].comType = 2;
    else _MemberList[ind].comType = 1;
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);
  };

  const F_AddDots = () => {
    if (G_MemberList.length >= 8) return;
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.push({
      ht: 0,
      hi: 0,
      bdDev_id: 0,
      comType: 1,
      dotGwID: 0,
      loraFreq: 433.0,
    });
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const F_DeleteDots = (ind) => {
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    _MemberList.splice(ind, 1);
    setG_MemberList(_MemberList);
    F_DelayToShowPlaceData(_MemberList);   
  };

  const F_UpdateInfo=()=>{
    let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    for (let i = 0; i < 8; i++) {
        if(document.getElementById(`in_${props.pos}_${i}_hi`)){
            _MemberList[i].hi = parseInt(document.getElementById(`in_${props.pos}_${i}_hi`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_ht`)){
            _MemberList[i].ht = parseInt(document.getElementById(`in_${props.pos}_${i}_ht`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_bdDev_id`)){
            _MemberList[i].bdDev_id = parseInt(document.getElementById(`in_${props.pos}_${i}_bdDev_id`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_loraFreq`)){
            _MemberList[i].loraFreq = parseFloat(document.getElementById(`in_${props.pos}_${i}_loraFreq`).value);
        }
        if(document.getElementById(`in_${props.pos}_${i}_dotGwID`)){
            _MemberList[i].dotGwID = parseInt(document.getElementById(`in_${props.pos}_${i}_dotGwID`).value);
        }
    }
    setG_MemberList(_MemberList);
  }

  const Fin_SetInput=(i, sPara, _MemberList)=>{
    if(document.getElementById(`in_${props.pos}_${i}_${sPara}`)){
        document.getElementById(`in_${props.pos}_${i}_${sPara}`).value = _MemberList[i][sPara];
    }
  }

  const fDisplay_Info=(_MemberList)=>{
    for (let i = 0; i < 8; i++) {
        Fin_SetInput(i, "hi", _MemberList);
        Fin_SetInput(i, "ht", _MemberList);
        Fin_SetInput(i, "bdDev_id", _MemberList);
        Fin_SetInput(i, "loraFreq", _MemberList);
        Fin_SetInput(i, "dotGwID", _MemberList);
    }
    clearTimeout(ref_UploadDelay.current);
  }

  const F_DelayToShowPlaceData=(_MemberList)=>{
    // let _MemberList = JSON.parse(JSON.stringify(G_MemberList));
    ref_UploadDelay.current = setTimeout(()=>fDisplay_Info(_MemberList), 100);      
  }

  const Fin_invalidNo=(num)=>{
    if(isNaN(num) || num<= 0 ){
        return true;
    }
    return false;
  }
  const F_DownloadDots=()=>{
    /** get the information */
    if(props.downloadDots){
        for (let i = 0; i < G_MemberList.length; i++) {
            if(Fin_invalidNo(G_MemberList[i].ht)) return toast(`Dots[${i+1}], invalid ht`);
            if(Fin_invalidNo(G_MemberList[i].hi)) return toast(`Dots[${i+1}], invalid hi`);
            if(Fin_invalidNo(G_MemberList[i].bdDev_id)) return toast(`Dots[${i+1}], invalid bdDev_id`);
    
            if(G_MemberList[i].comType===1){    // broker link
                if(G_MemberList[i].loraFreq < 433 || G_MemberList[i].loraFreq > 440){
                    return toast(`Dots[${i+1}], invalid loraFreq`);
                }
            }else{      // direct lora
                if(Fin_invalidNo(G_MemberList[i].dotGwID)) return toast(`Dots[${i+1}], invalid dotGwID`);
            }
        }
        props.downloadDots(G_MemberList);
    }
  }

  const F_uploadCfgDots=()=>{
    if(props.uploadCfgDots){
        props.uploadCfgDots();
    }
  }

  return (
    <div className="spring_SL_TabPg_NonPadding" style={{ padding: "0px 10px" }}>
      <div className="sortHor">
        <div className="spring_leaderTitle" style={{ marginLeft: "15px" }}>Dots</div>
        
        <div className="stayRight sortHor">
          <div className={`${props.gwId!==G_UploadedGwID ?"spring_smallText redText":"spring_smallText"}`} style={{ marginRight: "10px" }}>
              {`GwID: ${G_UploadedGwID}`}
          </div>
          <MdOutlineFileUpload
            className="reactCfgButtonOn hoverPointer"
            style={{ marginRight: "30px" }}
              onClick={F_uploadCfgDots}
        />
        </div>
      </div>
      <div className="spring_SL_DotsList">
        {G_MemberList.map((c, ind) => (
          <div className="sortHor" key={`DotList_${props.pos}_${ind}`}>
            <div className="spring_marginRight5">{`${ind + 1}.`}</div>
            <div className="spring_SL_CfgDotsFrame">
              <div className="sortHor">
                <div className="sortHor">
                  <div>ht:</div>
                  <input
                    style={{ width: "45px" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_ht`}
                    onChange={F_UpdateInfo}
                  ></input>
                </div>
                <div className="sortHor">
                  <div>hi:</div>
                  <input
                    style={{ width: "45px" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_hi`}
                    onChange={F_UpdateInfo}
                  ></input>
                </div>
                <div className="sortHor">
                  <div>bdDev_id:</div>
                  <input
                    style={{ width: "45px" }}
                    type={"number"}
                    className=" i_time_contentInput hideNumberUpDown"
                    id={`in_${props.pos}_${ind}_bdDev_id`}
                    onChange={F_UpdateInfo}
                  ></input>
                </div>
              </div>

              <div className="sortHor">
                <div className="sortHor">
                  <div>comType:</div>
                  {c.comType === 1 && (
                    <div
                      className="spring_SL_SelButton hoverPointer"
                      style={{ margin: "0 10px 0 5px" }}
                      onClick={() => F_ToggleComType(ind)}
                    >
                      Direct
                    </div>
                  )}
                  {c.comType === 2 && (
                    <div
                      className="spring_SL_SelButton hoverPointer"
                      style={{ margin: "0 10px 0 5px" }}
                      onClick={() => F_ToggleComType(ind)}
                    >
                      Broker
                    </div>
                  )}
                </div>
                {c.comType === 2 && (
                  <div className="sortHor">
                    <div>dotGwID:</div>
                    <input
                      style={{ width: "30%" }}
                      type={"number"}
                      className=" i_time_contentInput hideNumberUpDown"
                      id={`in_${props.pos}_${ind}_dotGwID`}
                      onChange={F_UpdateInfo}
                    ></input>
                  </div>
                )}
                {c.comType === 1 && (
                  <div className="sortHor">
                    <div>loraFreq:</div>
                    <input
                      style={{ width: "30%" }}
                      type={"number"}
                      className=" i_time_contentInput hideNumberUpDown"
                      id={`in_${props.pos}_${ind}_loraFreq`}
                      onChange={F_UpdateInfo}
                    ></input>
                  </div>
                )}
              </div>
            </div>
            <div
              className="hoverPointer redText"
              style={{ marginLeft: "5px" }}
              onClick={() => F_DeleteDots(ind)}
            >
              <FiTrash2 />
            </div>
          </div>
        ))}
        {G_MemberList.length < 8 && <div className="spring_SL_CfgDotsFrame flexAndBothCenter hoverPointer boldText" onClick={F_AddDots} style={{ margin: "0 20px 0 17px" }}> 
          + Add
        </div>}
        {G_MemberList.length >= 8 && <div className="spring_SL_CfgDotsFrame">
          Max
        </div>}
        <div className="spring_tpContentSendButton hoverPointer" 
        onClick={F_DownloadDots}>
          Send
        </div>

      </div>
    </div>
  );
};

export default SLTabCfgDots;
